<template>
	<div class="ns-coupon" style="       padding: 0px 100px 0px 90px;
    width: 1372px;">
		<div class="moduleWarp" data-sort="1" id="module_635865" moduleid="635865" relatedid="1344" data-level="" module-type="4" style="    background-color: rgb(243 243 243);
    display: block;
    position: absolute;
           width: 100%;
           left: 0px;">
		        <div class="ContentShow ">
		                                        <!-- 单图片广告start -->
		<div class="LeftClickLi  module-user-recommend" data-level="" moduleid="635865">
		    <ul class="module_banner over-in placeholderJs">
		        <li bannerid="1028727">
		            <a thisid="1028727"  target="_blank">
					 <el-image :src="$img(this.adList2[1].adv_image)" fit="cover" @click="$router.pushToTab(this.adList2[1].adv_url.url)" />
					 
					 
					 </a>
		                                    
		            <!--首页一分广告(带左右滚动产品) END-->
		                </li>
		        </ul>
		</div>
		
		<!-- 单图片广告end -->
		
		            </div>
		    </div>
			<div style="    padding-top: 250px;">
			</div>
		<div class="ns-coupon-info" v-if="adList.length" style="    height: 434px;">
			
			<div class="ns-coupon-img" v-loading="loadingAd" style="    width: 1184px;">
				<el-carousel height="390px">
					<el-carousel-item v-for="item in adList" :key="item.adv_id">
						<el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" />
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<el-tabs>
			<div v-loading="loading">
				
				
				
				
				
				
				
				
				<ul class="ns-coupon-list" style="display:none">
					<li class="ns-bg-color ns-coupon-li" v-for="(item, index) in couponList" :key="index" :class="item.count == item.lead_count ? 'no-coupon' : '' ">
						<div class="describe">
							<template>
								<span v-if="!item.discount || item.discount == '0.00'">฿{{ item.money }}</span>
								<span v-else>{{ 100-(item.discount*10) }} %</span>
							</template>
							<span>{{ activeName == 'first' ? item.coupon_name : item.platformcoupon_name }}</span>
							<span v-if="activeName == 'first'">{{ item.goods_type == 1 ? 'ผลิตภัณฑ์ทั้งหมด' : 'ผลิตภัณฑ์ที่กําหนด' }}</span>
							<template>
								<span v-if="item.at_least == '0.00'">ไม่มีคูปองเกณฑ์</span>
								<span v-else>เต็ม{{ item.at_least }}มีอยู่</span>
							</template>

							<template>
								<span class="coupon-wrap-time" v-if="item.validity_type">วันที่รวบรวม{{ item.fixed_term }}ใช้ได้หลายวัน</span>
								<span class="coupon-wrap-time" v-else>ใช้ได้ถึงวันที่{{ $timeStampTurnTime(item.end_time) }}</span>
							</template>
						</div>
						<div class="receive">
							<!-- หากขีด จํากัด คือ0 หรือ จํานวนการเรียกร้องค่าสินไหมทดแทนน้อยกว่าจํานวนการเรียกร้องค่าสินไหมทดแทนสูงสุด -->
							<a class="ns-text-color" v-if="item.count == item.lead_count">							
								<span>ขายหมดแล้ว</span>
							</a>
							<a class="ns-text-color" @click="couponTap(item, index)" v-else>							
								<span v-if="item.useState == 0">รับสิทธิ์ตอนนี้เลย</span>
								<span v-else>ไปและใช้</span>
							</a>
						</div>
					</li>
				</ul>








				<div class="empty-wrap" v-if="couponList.length <= 0">
					<div class="ns-text-align">ไม่มีคูปอง</div>
				</div>
				
				
				
<div class="moduleWarp" data-sort="1" id="module_635670" moduleid="635670" relatedid="1344" data-level="" module-type="4" style="display: block;">
        <div class="ContentShow ">
                                        <!-- 单图片广告start -->
<div class="LeftClickLi  module-user-recommend" data-level="" moduleid="635670">
    <ul class="module_banner over-in placeholderJs">
        <li bannerid="1028662" class="li-nohover">
            <a thisid="1028662" href="javascript:void(0);"><el-image :src="$img(this.adList2[0].adv_image)" fit="cover" @click="$router.pushToTab(this.adList2[0].adv_url.url)" /></a>
                                    
            <!--首页一分广告(带左右滚动产品) END-->
                </li>
        </ul>
</div>

<!-- 单图片广告end -->

            </div>
    </div>
	<div class="moduleWarp" data-sort="" id="module_632998" moduleid="632998" relatedid="1344" data-level="" module-type="43" style="display: block; padding-top: 20px;" >
	        <div class="ContentShow ">
	                                       <div class="adv_module_43 New_clear" data-level="" id="couponModule_632998" moduleid="632998" :style="{ 'background': 'url(' +  this.adList3[0].adv_image  + ')' }" style="
	                                       background-repeat: round;
	                                       background-size: cover;">
	    
	    <!-- 优惠卷 -->
	    <span class="adv_module_43_bg_left" style="display: none;"></span>
	    <span class="adv_module_43_bg_right"  style="display: none;"></span>
	            <div class="coupon-module-warp">
	        <ul>
	            <li class="ky-coupon-item " id="ky-coupon-item-5204131" data-id="" v-for="(item, index) in couponList" :key="index" :class="item.count == item.lead_count ? 'no-coupon' : '' " >
			<div class="ky-coupon-item-margin">
			<div class="ky-coupon-bg">
										<img src="https://s2.konvy.com/static/img/new_coupon/p_coupon_bg.png" class="ky-v-top" alt="coupon"> 
			</div>
			<div class="ky-coupon-cont">
				<div class="ky-coupon-l">
													<img :src="$img(siteInfo.logo)" class="ky-coupon-logo" alt="">
												
													
													<p class="ky-coupon-participant ky-limit-more" v-if="activeName == 'first'">{{ item.goods_type == 1 ? 'ผลิตภัณฑ์ทั้งหมด' : 'ผลิตภัณฑ์ที่กําหนด' }}</p>
													
													
								</div>
								
								
								
								
								
				<div class="ky-coupon-r" v-if="!item.discount || item.discount == '0.00'" >
					<div class="ky-coupon-r-cont ky-pos  ">
						<div class="ky-coupon-rl-wrap">
							<p class="ky-coupon-type ky-limit-one">
															<!-- Deduct -->
								<span class="ky-coupon-deduct "><span class="ky-money ky-lh-1">฿</span>{{ item.money }}</span>
														</p>
							<p class="ky-coupon-period-text ky-d-ib ky-text-regular ky-limit-more" >สั่งซื้อขั้นต่ำ {{ item.at_least }} บาท</p>
													<div class="ky-coupon-condition-wrap">
															<span v-if="item.at_least == '0.00'">ไม่มีคูปองเกณฑ์</span>
																<span v-else>ยอดขั้นต่ำ {{ item.at_least }}</span>
														</div>
												</div>
												
												
												
												
												
											<div class="ky-coupon-rr-wrap">
																			<!-- 可领取 -->
							<a class="ky-coupon-button" href="javascript:;" onclick="X.getCoupon(this, 0)" coupon-id="5204131" bannerid="1026234" v-if="item.count == item.lead_count" >หมดแล้ว</a>
							
							
							<a class="ky-coupon-button" href="javascript:;" onclick="X.getCoupon(this, 0)" coupon-id="5204131" bannerid="1026234"  @click="couponTap(item, index)" v-else ><span v-if="item.useState == 0">เก็บคูปอง</span>
																				<span v-else>ใช้เลย</span></a>
												</div>
										</div>
					<div class="ky-coupon-period">
					
						
						<span class="ky-limit-one " v-if="item.validity_type">คูปองมีอายุ {{ item.fixed_term }} </span>
						<span class="ky-limit-one "  v-else>ใช้ได้ถึงวันที่ {{ $timeStampTurnTime(item.end_time) }} </span>
						
						
						
											<div class="ky-float-r ky-pos ky-coupon-more">
							<i class="ky-coupon-more-icon"></i>
							<div class="ky-coupon-more-fixed">
								<div class="ky-coupon-more-wrap">
									<ul>
										<li>
											<p>ระยะเวลา</p>
																				<span v-if="item.validity_type" >คูปองมีอายุ {{ item.fixed_term }}</span>
																				<span v-else >ใช้ได้ถึงวันที่ {{ $timeStampTurnTime(item.end_time) }} </span>
																				</li>
										<li>
											<p>เงื่อนไข</p>
											<span>1.สั่งซื้อขั้นต่ำ {{ item.at_least }} บาท<br>2.คูปองสำหรับผู้ใช้ใหม่เท่านั้น<br>3.คูปองนี้ไม่สามารถใช้ร่วมกับคูปองอื่นได้</span>
										</li>
										<li>
											<p>ข้อจำกัด</p>
											<span>{{ activeName == 'first' ? item.coupon_name : item.platformcoupon_name }}</span>
											<span v-if="activeName == 'first'">{{ item.goods_type == 1 ? 'ผลิตภัณฑ์ทั้งหมด' : 'ผลิตภัณฑ์ที่กําหนด' }}</span>
										</li>
										<li>
											<p>ข้อมูลเพิ่มเติม</p>
											<span>กดรับได้ 1 ครั้ง เท่านั้น</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
										</div>
				</div>
				
				
				<div class="ky-coupon-r" v-else >
					<div class="ky-coupon-r-cont ky-pos  ">
						<div class="ky-coupon-rl-wrap">
							<p class="ky-coupon-type ky-limit-one">
															<!-- Deduct -->
								<span class="ky-coupon-deduct ">{{ 100-(item.discount*10) }} <span class="ky-money ky-lh-1">%</span> </span>
														</p>
							<p class="ky-coupon-period-text ky-d-ib ky-text-regular ky-limit-more" title="สั่งซื้อขั้นต่ำ 1,499 บาท">สั่งซื้อขั้นต่ำ {{ item.at_least }} บาท</p>
													<div class="ky-coupon-condition-wrap">
															<span v-if="item.at_least == '0.00'">ไม่มีคูปองเกณฑ์</span>
																<span v-else>ยอดขั้นต่ำ {{ item.at_least }}</span>
														</div>
												</div>
											<div class="ky-coupon-rr-wrap">
																		
							<a class="ky-coupon-button" href="javascript:;" onclick="X.getCoupon(this, 0)" coupon-id="5204131" bannerid="1026234" v-if="item.count == item.lead_count" >หมดแล้ว</a>
							
							
							<a class="ky-coupon-button" href="javascript:;" onclick="X.getCoupon(this, 0)" coupon-id="5204131" bannerid="1026234"  @click="couponTap(item, index)" v-else ><span v-if="item.useState == 0">เก็บคูปอง</span>
																				<span v-else>ใช้เลย</span></a>
												</div>
										</div>
					<div class="ky-coupon-period">
						
						<span class="ky-limit-one " v-if="item.validity_type">คูปองมีอายุ {{ item.fixed_term }} </span>
						<span class="ky-limit-one "  v-else>ใช้ได้ถึงวันที่ {{ $timeStampTurnTime(item.end_time) }} </span>
											<div class="ky-float-r ky-pos ky-coupon-more">
							<i class="ky-coupon-more-icon"></i>
							<div class="ky-coupon-more-fixed">
								<div class="ky-coupon-more-wrap">
									<ul>
										<li>
											<p>ระยะเวลา</p>
																					<span v-if="item.validity_type" >คูปองมีอายุ {{ item.fixed_term }}</span>
																					<span v-else >ใช้ได้ถึงวันที่ {{ $timeStampTurnTime(item.end_time) }} </span>
																				</li>
										<li>
											<p>เงื่อนไข</p>
											<span>1.สั่งซื้อขั้นต่ำ {{ item.at_least }} บาท<br>2.คูปองสำหรับผู้ใช้ใหม่เท่านั้น<br>3.คูปองนี้ไม่สามารถใช้ร่วมกับคูปองอื่นได้</span>
										</li>
										<li>
											<p>ข้อจำกัด</p>
											<span>{{ activeName == 'first' ? item.coupon_name : item.platformcoupon_name }}</span>
											<span v-if="activeName == 'first'">{{ item.goods_type == 1 ? 'ผลิตภัณฑ์ทั้งหมด' : 'ผลิตภัณฑ์ที่กําหนด' }}</span>
										</li>
										<li>
											<p>ข้อมูลเพิ่มเติม</p>
											<span>กดรับได้ 1 ครั้ง เท่านั้น</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
										</div>
				</div>
				
				
				
				
				
				
				
				
			</div>
		</div>
	</li>
	
	        </ul>
	    </div>
	        <!-- End 优惠卷 -->
	</div>
	            </div>
	    </div>
	
	<div class="moduleWarp" data-sort="1" id="module_222087" moduleid="222087" relatedid="1344" data-level="" module-type="4" style="   
    display: block;
    padding-top: 34px;">
	        <div class="ContentShow ">
	                                        <!-- 单图片广告start -->
	<div class="LeftClickLi  module-user-recommend" data-level="" moduleid="222087">
	    <ul class="module_banner over-in placeholderJs">
	        <li bannerid="318191" class="li-nohover">
	            <a thisid="318191" href="javascript:void(0);"> <el-image :src="$img(this.adList2[3].adv_image)" fit="cover" @click="$router.pushToTab(this.adList2[3].adv_url.url)" /></a>
	                                    
	            <!--首页一分广告(带左右滚动产品) END-->
	                </li>
	        </ul>
	</div>
	
	<!-- 单图片广告end -->
	
	            </div>
	    </div>
	<div class="moduleWarp isTitleWarp" data-sort="" id="module_855" moduleid="855" relatedid="1344" data-level="" module-type="3" style="background-color: rgb(243, 243, 243); display: block; ">
	        <div class="ContentShow ">
	                                        <!-- 标题模板start -->
	
	<!-- 标题模板end -->            </div>
	    </div>
	<div class="moduleWarp" data-sort="" id="module_227775" moduleid="227775" relatedid="" data-level="" module-type="67" style="display: block;      border-radius: 30px;">
	        <div class="ContentShow ">
	                                        <div class="group-module" id="pv-module-227775" data-level="" moduleid="227775">
	    <!-- 可切换多模块 -->
	    <div class="swiper-team-warp swiper-no-swiping" id="swiper_team_227775">
	        <ul class="swiper-wrapper swiper-no-swiping">
	            <!-- 页数 -->
	                                    <li class="swiper-slide swiper-no-swiping">
	                                                            <!-- 单图片广告start -->
	<div class="LeftClickLi  module-user-recommend" data-level="" moduleid="227776">
	    <ul class="module_banner over-in placeholderJs">
	        <li bannerid="206179">
	            <a thisid="206179" target="_blank"> <el-image :src="$img(this.adList2[2].adv_image)" fit="cover" @click="$router.pushToTab(this.adList2[2].adv_url.url)" /></a>
	                                    
	            <!--首页一分广告(带左右滚动产品) END-->
	                </li>
	        </ul>
	</div>
	
	<!-- 单图片广告end -->
	
	                                        </li>
	                                </ul>
	    </div>
	    <a href="javascript:;" class="swiper_prev_nst swiper-no-swiping" onclick="fn_swiper('227775','left');" id="swiper_left_227775"></a>
	    <a href="javascript:;" class="swiper_next_nst swiper-no-swiping" onclick="fn_swiper('227775','right');" id="swiper_right_227775"></a>
	    <div class="swiper-pagination-warp public-swiper-pagination"><div class="swiper-pagination" id="sw-pagination-227775"></div></div>
	    
	    <!-- 可切换多模块 end -->
	</div>
	            </div>
	    </div>
	
			
			
			
			
				<div class="pager">
					<el-pagination background :pager-count="5" :total="total" prev-text="ก่อน" next-text="ต่อไป" :current-page.sync="currentPage"
					 :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"
					 hide-on-single-page></el-pagination>
				</div>
			</div>
		</el-tabs>
	</div>
</template>

<script>
	import {
		couponList,
		receiveCoupon
	} from '@/api/coupon';
	import {
		mapGetters
	} from 'vuex';
	import {
		adList
	} from '@/api/website';

	export default {
		name: 'coupon',
		components: {},
		data: () => {
			return {
				couponList: [],
				total: 0,
				currentPage: 1,
				pageSize: 9,
				couponBtnSwitch: false,
				activeName: 'first',
				loading: true,
				loadingAd: true,
				adList: [],
				adList2: [],
				adList3: []
			};
		},
		created() {
			if (this.addonIsExit && this.addonIsExit.coupon != 1) {
				this.$notify({
					message: 'ไม่ได้ติดตั้งปลั๊กอินคูปอง',
					title: 'คำเตือน',type: 'warning',
					duration: 2000,
					onClose: () => {
						this.$route.push('/');
					}
				});
			} else {
				this.getAdList();
				this.getAdList2();
				this.getAdList3();
				this.getCanReceiveCouponQuery();
			}
		},
		computed: {
			...mapGetters(['addonIsExit', "siteInfo"])
		},
		watch: {
			addonIsExit() {
				if (this.addonIsExit.coupon != 1) {
					this.$notify({
						message: 'ไม่ได้ติดตั้งปลั๊กอินคูปอง',
						title: 'คำเตือน',type: 'warning',
						duration: 2000,
						onClose: () => {
							this.$route.push('/');
						}
					});
				}
			}
		},
		methods: {
			getAdList() {
				adList({
						keyword: 'NS_PC_COUPON'
					})
					.then(res => {
						this.adList = res.data.adv_list;
						for (let i = 0; i < this.adList.length; i++) {
							if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url);
							console.log(this.adList[i].adv_url);
						}
						this.loadingAd = false;
					})
					.catch(err => {
						this.loadingAd = false;
					});
			},
			getAdList2() {
				adList({
						keyword: 'NS_PC_COUP2'
					})
					.then(res => {
						this.adList2 = res.data.adv_list;
						console.log(this.adList2);
						for (let i = 0; i < this.adList2.length; i++) {
							if (this.adList2[i].adv_url) this.adList2[i].adv_url = JSON.parse(this.adList2[i].adv_url);
							console.log(this.adList[i].adv_url);
						}
						this.loadingAd = false;
					})
					.catch(err => {
						this.loadingAd = false;
					});
			},
			getAdList3() {
				adList({
						keyword: 'NS_PC_COUP3'
					})
					.then(res => {
						this.adList3 = res.data.adv_list;
						console.log(this.adList3);
						for (let i = 0; i < this.adList3.length; i++) {
							if (this.adList3[i].adv_url) this.adList3[i].adv_url = JSON.parse(this.adList2[i].adv_url);
							console.log(this.adList[i].adv_url);
						}
						this.loadingAd = false;
					})
					.catch(err => {
						this.loadingAd = false;
					});
			},
			handleClick(tab, event) {
				this.loading = true;
				// (this.currentPage = 1), this.getCanReceiveCouponQuery();
			},
			/**
			 * คูปองของฉัน
			 */
			myCoupon() {
				this.$router.pushToTab('/member/my_coupon');
			},
			/**
			 * รับรายการคูปอง
			 */
			getCanReceiveCouponQuery() {
				couponList({
						page: this.currentPage,
						page_size: this.pageSize,
						activeName: this.activeName
					})
					.then(res => {
						this.couponList = res.data.list;
						console.log(this.couponList)
						this.total = res.data.count;
						this.couponList.forEach(v => {
							v.useState = 0;
						});
						this.loading = false;
					})
					.catch(err => {
						this.loading = false;
						this.$notify.error({title: 'Error',message: err.message});
					});
			},
			handlePageSizeChange(size) {
				this.pageSize = size;
				this.loading = true;
				this.getCanReceiveCouponQuery();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				this.loading = true;
				this.getCanReceiveCouponQuery();
			},
			/**
			 * คูปองการคลิก
			 */
			couponTap(item, index) {
				if (item.useState == 0) this.receiveCoupon(item, index);
				else this.toGoodsList(item);
			},
			/**
			 * รับเวาเชอร์ของคุณ
			 */
			receiveCoupon(item, index) {
				if (this.couponBtnSwitch) return;
				this.couponBtnSwitch = true;

				var data = {
					site_id: item.site_id,
					activeName: this.activeName
				};
				data.coupon_type_id = item.coupon_type_id;

				receiveCoupon(data)
					.then(res => {
						// console.log(res)
						// console.log('12312313')
						// return false
						var data = res.data;
						let msg = res.message;
						if (res.code == 0) {
							msg = 'เคลมสําเร็จ';
							this.$notify({
								message: msg,
								title: 'เรียบร้อยแล้ว',type: 'success'
							});
						} else {
							this.$notify({
								message: msg,
								title: 'คำเตือน',type: 'warning'
							});
						}
						let list = this.couponList;
						if (res.data.is_exist == 1) {
							for (let i = 0; i < list.length; i++) {
								if (this.activeName == 'first') {
									if (list[i].coupon_type_id == item.coupon_type_id) {
										list[i].useState = 1;
									}
								} else {
									if (list[i].platformcoupon_type_id == item.platformcoupon_type_id) {
										list[i].useState = 1;
									}
								}
							}
						} else {
							for (let i = 0; i < list.length; i++) {
								if (this.activeName == 'first') {
									if (list[i].coupon_type_id == item.coupon_type_id) {
										list[i].useState = 2;
									}
								} else {
									if (list[i].platformcoupon_type_id == item.platformcoupon_type_id) {
										list[i].useState = 2;
									}
								}
							}
						}

						this.couponBtnSwitch = false;
						this.$forceUpdate();
					})
					.catch(err => {
						if( err.message == 'คุณยังไม่ได้เข้าสู่ระบบ，กรุณาเข้าสู่ระบบก่อน' ){
							this.$router.push('/login');
						} else {
							this.$notify.error({title: 'Error',message: err.message});
						}
						this.couponBtnSwitch = false;
					});
			},
			/**
			 * ไปซื้อ
			 */
			toGoodsList(item) {
				if (this.activeName == 'first') {
					if (item.goods_type != 1) {
						this.$router.push({
							path: '/list',
							query: {
								coupon: item.coupon_type_id
							}
						});
					} else {
						this.$router.push({
							path: '/list',
						});
					}
				} else {
					this.$router.push('/list');
				}
			}
		}
	};
</script>
<style lang="scss" scoped>
	.empty-wrap {
		margin-top: 20px;
	}

	.ns-coupon {
		width: 100%;
		padding: 20px;
		box-sizing: border-box;
	}

	.ns-coupon-info {
		background: url(../../assets/images/coupon-bg.png) no-repeat;
		background-size: cover;
		width: 100%;
		height: 450px;
		display: flex;

		.ns-coupon-wrap {
			width: 320px;
			padding: 20px;
			box-sizing: border-box;
			text-align: center;

			.coupon-name {
				margin: 45px 0 50px;

				span:nth-child(1) {
					display: block;
					line-height: 45px;
					font-size: 30px;
				}

				.ns-text-color-gray {
					color: #898989 !important;
				}

				.text12 {
					font-size: 12px;
				}
			}

			.coupon-type {
				margin-left: 20px;

				li {
					float: left;
					width: 80px;
					height: 100px;

					i {
						display: block;
						width: 50px;
						height: 50px;
						margin: 8px auto;
					}

					&:nth-child(1) i {
						background: url(../../assets/images/limited_time.png) no-repeat center;
					}

					&:nth-child(2) i {
						background: url(../../assets/images/superposition.png) no-repeat center;
					}

					&:nth-child(3) i {
						background: url(../../assets/images/coupon_type.png) no-repeat center;
					}
				}
			}

			.el-button {
				width: 200px;
				background-color: $base-color;
				color: #fff;
				margin-top: 70px;
				font-size: 18px;
			}
		}

		.ns-coupon-img {
			width: 850px;
			padding: 20px;
			box-sizing: border-box;

			img {
				width: 100%;
				height: 100%;
			}

			.el-carousel__item:nth-child(2n) {
				background-color: #99a9bf;
			}

			.el-carousel__item:nth-child(2n + 1) {
				background-color: #d3dce6;
			}
		}
	}

	.el-tabs {
		margin-top: 20px;
	}

	.ns-coupon-list {
		display: flex;
		flex-wrap: wrap;
		padding: 0 20px;

		.ns-coupon-li {
			background: url(../../assets/images/list_bj.png) no-repeat;
			width: 32%;
			height: 169px;
			margin-bottom: 20px;
			margin-right: 2%;
			background-size: cover;
			&.no-coupon {
				
				background: url(../../assets/images/no_coupon.png) no-repeat !important;
				background-color : #e6e6e6 !important;
				.describe{
					color : #999 !important;
				}
				.receive {
					.ns-text-color {
						color : #999 !important;
					}
				}
			}	
			.describe {
				float: left;
				width: 250px;
				height: inherit;
				text-align: center;
				color: #fff;

				span {
					display: block;
				}

				span:nth-child(1) {
					font-size: 40px;
					margin-top: 20px;
					margin-bottom: 3px;
					line-height: 50px;
				}

				span:nth-child(3),
				span:nth-child(4),
				span:nth-child(5) {
					font-size: 12px;
					margin-left: 15px;
					line-height: 20px;
				}
			}

			.receive {
				float: right;
				width: 95px;
				height: inherit;
				text-align: center;

				a {
					display: inline-block;
					width: 30px;
					height: 120px;
					line-height: 120px;
					padding: 0 5px;
					margin-top: 25px;
					background-color: #fff;
					border-radius: 15px;
					cursor: pointer;
					box-sizing: border-box;

					span {
						display: inline-block;
						line-height: 20px;
						vertical-align: middle;
					}
				}
			}

			&:nth-child(3n) {
				margin-right: 0;
			}
		}
	}/*! CSS Used from: https://www.mercular.com/bower_components/bootstrap/dist/css/bootstrap.min.css ; media=all */

@media all {
	*,
	::after,
	::before {
		box-sizing: border-box;
	}
	a {
		color: #007bff;
		text-decoration: none;
		background-color: transparent;
	}
	a:hover {
		color: #0056b3;
		text-decoration: underline;
	}
	a:not([href]):not([tabindex]) {
		color: inherit;
		text-decoration: none;
	}
	a:not([href]):not([tabindex]):focus,
	a:not([href]):not([tabindex]):hover {
		color: inherit;
		text-decoration: none;
	}
	a:not([href]):not([tabindex]):focus {
		outline: 0;
	}
	@media print {
		*,
		::after,
		::before {
			text-shadow: none!important;
			box-shadow: none!important;
		}
		a:not(.btn) {
			text-decoration: underline;
		}
	}
}


/*! CSS Used from: https://www.mercular.com/css/front/seed/seed.css ; media=all */

@media all {
	a {
		background-color: transparent;
	}
	a:active,
	a:hover {
		outline: 0;
	}
	*,
	*:before,
	*:after {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}
	a,
	a:hover {
		text-decoration: none;
	}
}


/*! CSS Used from: https://www.mercular.com/css/front/style.css?build=638c8b7076974f48e1681bc048f96a5a02a294c8 ; media=all */

@media all {
	a {
		color: #3f4d56;
		-webkit-transition: 300ms;
		-moz-transition: 300ms;
		-o-transition: 300ms;
		transition: 300ms;
	}
	a.link {
		color: #0690f0;
	}
	a.link:hover {
		color: #0572be;
	}
}


/*! CSS Used from: https://www.mercular.com/css/front/styles-new.css?build=638c8b7076974f48e1681bc048f96a5a02a294c8 ; media=all */

@media all {
	*:not(body) {
		-webkit-tap-highlight-color: transparent;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	div {
		border: 0;
		margin: 0;
		padding: 0;
	}
	a {
		border: 0;
		font-size: 100%;
		vertical-align: baseline;
		margin: 0;
		padding: 0;
	}
	::-webkit-scrollbar {
		//display: none;
	}
	::-webkit-scrollbar-button {
		//display: none;
	}
	::-webkit-scrollbar-track {
		//background: none;
	}
	::-webkit-scrollbar-thumb {
		background: #B7B7B7;
		min-height: 40px;
		border-radius: 6px;
		display: none;
	}
	a {
		cursor: pointer;
	}
	a {
		text-decoration: none;
		color: #1B1B1B;
	}
	:focus {
		outline: 0;
	}
	
	.coupon___cart__flex {
		display: inline-flex;
		flex-direction: column;
		overflow: hidden;
		min-width: 200px;
		margin: 10px;
		position: relative;
	}
	.coupon___cart__top {
		float: left;
		width: 200px;
		height: 170px;
		position: relative;
		background: #ffffff;
		border-left: 1px solid #e8e8e8;
		border-top: 1px solid #e8e8e8;
		border-right: 1px solid #e8e8e8;
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px 6px 29px 6px;
	}
	.coupon___cart__top .circle-coupon__top {
		height: 20px;
		width: 20px;
		background: #fafafa;
		position: absolute;
		border-radius: 50%;
		bottom: -10px;
		z-index: 1;
		border: 1px solid #e8e8e8;
	}
	.coupon___cart__top .circle-coupon__top.circle__left {
		left: -10px;
	}
	.coupon___cart__top .circle-coupon__top.circle__right {
		left: 190px;
	}
	.coupon___cart__top .got-coupon-card__top {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 140px;
	}
	.coupon___cart__top .got-coupon-card__top .coupon-headers {
		font-weight: bold;
		font-size: 26px;
		color: #e31f26;
		height: 28px;
	}
	.coupon___cart__top .got-coupon-card__top .coupon-price {
		font-weight: bold;
		font-size: 34px;
		color: #000000;
		height: 56px;
		display: flex;
		align-items: center;
	}
	@media (max-width: 1024px) {
		.coupon___cart__top .got-coupon-card__top .coupon-price {
			font-size: 24px;
		}
	}
	.coupon___cart__top .got-coupon-card__top .coupon-description {
		font-size: 12px;
		width: 180px;
		height: auto;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-align: center;
	}
	.coupon___cart__top .got-coupon-card__top .coupon-description-2 {
		-webkit-line-clamp: 1;
	}
	.coupon___cart__bottom {
		float: left;
		width: 200px;
		height: 78px;
		position: relative;
		background: #e31f26;
		border-left: 1px solid #e8e8e8;
		border-right: 1px solid #e8e8e8;
		border-bottom: 1px solid #e8e8e8;
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.coupon___cart__bottom .got-coupon-card__bottom {
		width: 130px;
		height: 44px;
		background-color: #fff;
		border-radius: 22px;
		display: flex;
		justify-content: center;
	}
	.coupon___cart__bottom .got-coupon-card__bottom .got__coupon__bottom {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.coupon-text {
		font-size: 16px;
		font-weight: bold;
		color: #1b1b1b;
	}
	@media (max-width: 1024px) {
		.coupon___cart__top {
			width: 128px;
			height: 108.56px;
		}
		.coupon___cart__flex {
			min-width: 128px;
			margin: 10px;
		}
		.coupon___cart__top .circle-coupon__top.circle__right {
			left: 117px;
		}
		.coupon___cart__bottom {
			width: 128px;
			height: 49.78px;
		}
		.coupon___cart__bottom {
			float: left;
		}
		.coupon___cart__bottom .got-coupon-card__bottom {
			width: 103px;
			height: 32px;
		}
		.coupon___cart__bottom .got-coupon-card__bottom .got__coupon__bottom {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.coupon___cart__top {
			padding: 0px;
		}
		.coupon___cart__top .circle-coupon__top {
			background-color: #fff;
		}
		.coupon___cart__top .got-coupon-card__top {
			height: 100%;
			justify-content: center;
		}
		.coupon___cart__top .got-coupon-card__top .coupon-headers {
			font-weight: bold;
			font-size: 16px;
			height: 20px;
		}
		.coupon___cart__top .got-coupon-card__top .coupon-description {
			width: 110px;
			font-size: 8px;
			height: auto;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-align: center;
		}
		.coupon-text {
			font-size: 14px;
		}
	}
}


/*! CSS Used fontfaces */
/*! CSS Used from: https://s2.konvy.com/static/min/css/j/j.v4.85.min.css?v=1626429966 ; media=screen */
@media screen{
div,ul,li{margin:0;padding:0;}
img{border:0;}
ul li{list-style:none;}
a{color:#f73173;text-decoration:none;}
a:hover{text-decoration:underline;}
}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/head_foot.min.css?v=1664360752 */
ul,li{list-style:none;}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/index.min.css?v=1654680463 ; media=screen */
@media screen{
div,ul,li{margin:0;padding:0;}
img{border:0;}
li{list-style:none;}
a{text-decoration:none;}
a:hover{text-decoration:underline;}
}
/*! CSS Used from: https://s2.konvy.com/static/min/css/utils/css/ky.min.css?v=1664420402 */
*{padding:0;margin:0;box-sizing:content-box;}
li,ul{list-style:none;}
img{border:none;}
a:hover,a{text-decoration:none;}
a{color:#333;}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/admin/adminbrand_flagship.min.css?v=1665338270 */
*{margin:0;padding:0;-webkit-text-size-adjust:none;}
li,ul{list-style:none;}
a{text-decoration:none;color:#333;}
img{vertical-align:middle;}
.ContentShow img{border:none;display:inline-block;overflow:hidden;max-width:100%;}
.ContentShow{width:1180px;margin:0 auto;display:none;color:#333;}
.LeftClickLi>.module_banner>li{margin-bottom:1.35%;}
.LeftClickLi>.module_banner>li:last-child{margin-bottom:0;}
.ContentShow .LeftClickLi{padding-bottom:0.1px;}
.moduleWarp{overflow:hidden;}
.LeftClickLi img{width:100%;display:block;overflow:hidden;}
.AllWarp .LeftClickLi img{vertical-align:middle;width:100%;}
.ContentShow .LeftClickLi li{overflow:hidden;}
.module_banner>li{position:relative;}
.over-in>li{overflow:inherit!important;height:auto!important;}
@media (max-width:1440px){
.ContentShow{width:1000px;}
}
@media (max-width:1360px){
.ContentShow{width:950px;}
}
.ContentShow{position:relative;}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/web/promo_new.min.css?v=1661745912 */
.ContentShow img{max-width:100%;}
@media (max-width:1440px){
.ContentShow{width:950px;}
}
.moduleWarp{display:none;}
.moduleWarp>.ContentShow{display:block;position:relative;}

.el-main{
    
    width: 100%;
   
}.ns-coupon-info .ns-coupon-wrap .el-button{
    width: 207px;
    height: 39px;
    background-color: #FE2F2F;
    font-family: 'sukhumvittadmai2', sans-serif !important;
    color: #fff;
    margin-top: 70px;
    font-size: 18px;
    border-radius: 51px;
}/*! CSS Used from: https://s2.konvy.com/static/min/css/j/j.v4.85.min.css?v=1626429966 ; media=screen */
@media screen{
div,ul,li{margin:0;padding:0;}
img{border:0;}
ul li{list-style:none;}
a{color:#f73173;text-decoration:none;}
a:hover{text-decoration:underline;}
}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/head_foot.min.css?v=1664360752 */
ul,li{list-style:none;}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/index.min.css?v=1654680463 ; media=screen */
@media screen{
div,ul,li{margin:0;padding:0;}
img{border:0;}
li{list-style:none;}
a{text-decoration:none;}
a:hover{text-decoration:underline;}
}
/*! CSS Used from: https://s2.konvy.com/static/min/css/utils/css/ky.min.css?v=1664420402 */
*{padding:0;margin:0;box-sizing:content-box;}
li,ul{list-style:none;}
img{border:none;}
a:hover,a{text-decoration:none;}
a{color:#333;}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/admin/adminbrand_flagship.min.css?v=1665338270 */
*{margin:0;padding:0;-webkit-text-size-adjust:none;}
li,ul{list-style:none;}
a{text-decoration:none;color:#333;}
img{vertical-align:middle;}
.ContentShow img{border:none;display:inline-block;overflow:hidden;max-width:100%;}
.ContentShow{width:1180px;margin:0 auto;display:none;color:#333;}
.LeftClickLi>.module_banner>li{margin-bottom:1.35%;}
.LeftClickLi>.module_banner>li:last-child{margin-bottom:0;}
.ContentShow .LeftClickLi{padding-bottom:0.1px;}
.moduleWarp{overflow:hidden;}
.LeftClickLi img{width:100%;display:block;overflow:hidden;}
.AllWarp .LeftClickLi img{vertical-align:middle;width:100%;}
.ContentShow .LeftClickLi li{overflow:hidden;}
.module_banner>li{position:relative;}
.ContentShow .li-nohover:hover{box-shadow:none;}
.ContentShow .li-nohover a{cursor:initial;}
.over-in>li{overflow:inherit!important;height:auto!important;}
@media (max-width:1440px){
.ContentShow{width:1000px;}
}
@media (max-width:1360px){
.ContentShow{width:950px;}
}
.ContentShow{position:relative;}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/web/promo_new.min.css?v=1661745912 */
.ContentShow img{max-width:100%;}
@media (max-width:1440px){
.ContentShow{width:950px;}
}
.moduleWarp{display:none;}
.moduleWarp>.ContentShow{display:block;position:relative;}/*! CSS Used from: https://s2.konvy.com/static/min/css/j/j.v4.85.min.css?v=1626429966 ; media=screen */
@media screen{
div,ul,li,p{margin:0;padding:0;}
img{border:0;}
ul li{list-style:none;}
a{color:#f73173;text-decoration:none;}
a:hover{text-decoration:underline;}
}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/head_foot.min.css?v=1664360752 */
.New_clear:after{content:".";display:block;height:0;visibility:hidden;clear:both;}
.New_clear{zoom:1;}
.New_clear{zoom:1;}
ul,li{list-style:none;}
i{font-style:normal;}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/index.min.css?v=1654680463 ; media=screen */
@media screen{
div,ul,li,p{margin:0;padding:0;}
img{border:0;}
li{list-style:none;}
a{text-decoration:none;}
a:hover{text-decoration:underline;}
}
/*! CSS Used from: https://s2.konvy.com/static/min/css/utils/css/ky.min.css?v=1664420402 */
*{padding:0;margin:0;box-sizing:content-box;}
li,ul{list-style:none;}
img{border:none;}
a:hover,a{text-decoration:none;}
a{color:#333;}
i{font-style:normal;}
.ky-text-regular{color:#666;}
.ky-lh-1{line-height:1;}
.ky-d-ib{display:inline-block;}
.ky-float-r{float:right;}
.ky-v-top{vertical-align:top;}
.ky-limit-one{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
.ky-limit-more{display:-webkit-box;-webkit-box-orient:vertical;overflow:hidden;-webkit-line-clamp:2;}
.ky-money{font-family:'Tahoma'!important;vertical-align:baseline;line-height:1;font-size:80%;}
.ky-pos{position:relative;}
/*! CSS Used from: https://s2.konvy.com/static/min/css/pc/css/konvy.min.css?v=1658495404 */
.moduleWarp .coupon-module-warp{padding-left:20px;}
.coupon-module-warp>ul{display:flex;flex-wrap:wrap;box-sizing:border-box;}
.coupon-module-warp li.ky-coupon-item{position:relative;}
.coupon-module-warp li.ky-coupon-item{width:366px;}
.coupon-module-warp li.ky-coupon-item:nth-child(3n){margin-right:0;}
.adv_module_43>span{background-repeat:no-repeat!important;height:100%!important;}
.adv_module_43>span.adv_module_43_bg_left{width:408px;}
.adv_module_43>span.adv_module_43_bg_right{background-position:right bottom!important;}
.ky-coupon-item{position:relative;display:flex;background-color:#fff;margin:0 20px 20px 0;width:366px;}
.ky-coupon-item img{max-width:100%;}
.ky-coupon-item-margin{margin:-1.0928% -1.6396% -2.1857%;}
.ky-coupon-cont{position:absolute;padding:1.0928% 1.6396% 2.1857%;width:100%;height:100%;box-sizing:content-box;display:flex;color:#333;top:-2.5%;}
.ky-coupon-l{width:24.56%;position:relative;text-align:center;height:100%;border-radius:.21334rem;overflow:hidden;}
.ky-coupon-logo{width:90%;font-size:0;position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);}
.ky-coupon-logo{width:calc((100% - 12px));}
.ky-coupon-r{width:calc(75.14% + 2px);padding-left:10px;position:relative;box-sizing:border-box;}
.ky-coupon-r-cont{height:calc(100% - 31px);display:flex;}
.ky-coupon-type{padding-top:13px;font-weight:bold;line-height:36px;display:flex;align-items:baseline;}
.ky-coupon-type>span{font-size:20px;}
.ky-coupon-type>span.ky-coupon-deduct{font-size:28px;}
.ky-coupon-type>span.ky-coupon-deduct>span{font-size:20px;}
.ky-coupon-period-text{font-size:12px;line-height:14px;margin-top:2px;width:100%;}
.ky-coupon-condition-wrap{position:absolute;bottom:8px;left:0;width:100%;height:18px;overflow:hidden;display:flex;flex-wrap:wrap;}
.ky-coupon-condition-wrap>span{border:1px solid #F73173;color:#F73173;line-height:15px;font-size:12px;display:inline-block;padding:0 4px;max-width:100%;box-sizing:border-box;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;padding-top:1px;vertical-align:top;margin-right:4px;}
.ky-coupon-condition-wrap>span:last-child{margin-right:0;}
.ky-coupon-participant{color:#999;font-size:12px;line-height:14px;position:absolute;bottom:8px;padding:0 5px;width:100%;box-sizing:border-box;letter-spacing:-.2px;}
.coupon-module-warp li.ky-coupon-item{background-color:transparent;box-shadow:none;overflow:initial;}
.ky-coupon-rl-wrap{width:calc(100% - 110px);position:relative;height:100%;}
.ky-coupon-rr-wrap{width:32.67%;padding:0 13px 0 10px;display:flex;flex-wrap:wrap;align-items:center;align-content:center;}
.ky-coupon-button{width:100%;height:32px;line-height:32px;font-size:14px;font-weight:bold;text-align:center;display:block;background:#F73173;border-radius:32px;color:#fff;position:relative;z-index:2;cursor:pointer;}
.ky-coupon-period{font-size:12px;border-top:1px solid rgba(0,0,0,.08);padding:8px 0;line-height:1;color:#999;position:absolute;bottom:0;width:calc(100% - 23px);box-sizing:border-box;line-height:14px;cursor:pointer;}
.ky-coupon-period>span{width:calc(100% - 30px);display:inline-block;vertical-align:top;}
.ky-coupon-more{cursor:pointer;}
.ky-coupon-more:hover .ky-coupon-more-fixed{display:block;}
.ky-coupon-more:hover .ky-coupon-more-icon{transform:rotate(360deg);}
.ky-coupon-more-icon{width:14px;height:14px;display:inline-block;background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAAeFJREFUSEu9lj1LQlEYx38iRGFDRIJGk1MFYkvlENF1aG1oa+o7CE4NDk2Cn6GtrS/QoA0NVsuNpJqcIgUlGuyF6GI84onb7V7vuSr3rOd/nt95Xs+JoLfmgEVgAZgGZgbHPoBPoAM8A69+5iI+giVgFZj1MzTY7wL3wJOX3gsYA9aBeU2QU/YC3ABvzg03YBzYBKZGhKljX8AV0LbbcQIFtgX4hVr3Lj3g0g61G5YwGhPwzHkZ8bSqwmsH7oyRMz+PJacXIlJAqcYNv1NqP5VKSWvQaDSkJXTXtVSvAu7qlr7AarXakVCy2exxAKi0zLkApalzOtdUsHg8viL6drv9EBBaEaA09rIf0AlT+oDQRwFuD0aWJ9MJq1arpyI2DOMgoKcdAQ7Nnxssl8udCahSqewHhHYFuAdE3dwbBlP6gFDLE6gDGwH67RnSZrNZTCQSaTEqOVNh9Eq03dNWq3WXTCaLLtp+SP8VTSaTiZmmeSKh1oG5eGql0+nDer3+7oD2i8a1LUqlUiYajUby+bzp1zL2/XK5vGZZVq9QKNy6nOu3hXbjBwF7aPuNL0t7tI0B/R1tYiPQ8B4R+md4i41QnycBhv4ACzTUL4ZKTaifKAUN9ZtoL8LQPsLOyp/YV/8HxVndNmCgpiUAAAAASUVORK5CYII=') no-repeat;background-size:100%;transform:rotate(180deg);transition:.3s;vertical-align:top;}
.ky-coupon-more-fixed{position:absolute;left:50%;margin-left:-150px;top:100%;width:300px;padding-top:14px;display:none;z-index:34;cursor:auto;}
.ky-coupon-more-wrap{background-color:#fff;border-radius:6px;box-shadow:0px 0px 12px 0px rgba(0,0,0,0.24);padding:13px;box-sizing:border-box;color:#333;line-height:18px;font-size:13px;}
.ky-coupon-more-wrap::after{content:'';width:0;height:0;top:3px;left:50%;-webkit-transform:translate(-50%,0%);transform:translate(-50%,00%);-ms-transform:translate(-50%,0%);-moz-transform:translate(-50%,0%);-o-transform:translate(-50%,0%);border-width:0 7px 7px;border-style:solid;border-color:transparent transparent #fff;position:absolute;top:7px;}
.ky-coupon-more-wrap>ul>li{padding-left:13px;margin-bottom:12px;position:relative;}
.ky-coupon-more-wrap>ul>li::after{content:'';position:absolute;left:1px;top:6px;width:5px;height:5px;background-color:#999;border-radius:5px;}
.ky-coupon-more-wrap>ul>li:last-child{margin-bottom:0;}
.ky-coupon-more-wrap>ul>li>span{color:#999;margin-top:6px;display:inline-block;word-break:break-word;}
@media (max-width:1440px){
.moduleWarp .coupon-module-warp{padding-left:16px;}
.moduleWarp .coupon-module-warp li.ky-coupon-item{margin:0 16px 16px 0;}
.moduleWarp .coupon-module-warp li.ky-coupon-item{width:295px;}
.moduleWarp .ky-coupon-type>span{font-size:16px;}
.moduleWarp .ky-coupon-type>span.ky-coupon-deduct{font-size:20px;}
.ky-coupon-type>span.ky-coupon-deduct>span{font-size:14px;}
.moduleWarp .ky-coupon-rl-wrap{width:calc(100% - 88px);}
.moduleWarp .ky-coupon-rr-wrap{width:65px;}
.moduleWarp .ky-coupon-button{line-height:28px;height:28px;font-size:12px;letter-spacing:-.3px;}
.moduleWarp .ky-coupon-period-text.ky-limit-more{-webkit-line-clamp:1;white-space:nowrap;text-overflow:ellipsis;display:block;}
}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/admin/adminbrand_flagship.min.css?v=1665338270 */
*{margin:0;padding:0;-webkit-text-size-adjust:none;}
li,ul{list-style:none;}
a{text-decoration:none;color:#333;}
img{vertical-align:middle;}
.New_clear:after{content:".";display:block;height:0;visibility:hidden;clear:both;}
.New_clear{zoom:1;}
.ContentShow img{border:none;display:inline-block;overflow:hidden;max-width:100%;}
.ContentShow{width:1180px;margin:0 auto;display:none;color:#333;}
.moduleWarp{overflow:hidden;}
@media (max-width:1440px){
.ContentShow{width:1000px;}
}
@media (max-width:1360px){
.ContentShow{width:950px;}
}
.ContentShow{position:relative;}
.adv_module_43{padding-top:22px;background-color:#fff3f5;position:relative;overflow:hidden;}
.adv_module_43>span{position:absolute;width:386px;height:383px;background-size:100%;}
.adv_module_43>span.adv_module_43_bg_left{background:url(https://s2.konvy.com/static/img/admin/module_coupon_new/bg_up.png);left:0;top:0;}
.adv_module_43>span.adv_module_43_bg_right{background:url(https://s2.konvy.com/static/img/admin/module_coupon_new/bg_down.png);right:0;bottom:0;}
@media (max-width:1440px){
.adv_module_43{padding-top:17px;}
}
@media (max-width:1360px){
.adv_module_43{padding-top:16px;}
}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/web/promo_new.min.css?v=1661745912 */
.ContentShow img{max-width:100%;}
@media (max-width:1440px){
.ContentShow{width:950px;}
}
@media (max-width:1440px){
.adv_module_43{padding-top:16px;}
}
.moduleWarp{display:none;}
.moduleWarp>.ContentShow{display:block;position:relative;}
/*! CSS Used from: Embedded */
#module_632998,#couponModule_632998{overflow:visible;}/*! CSS Used from: https://s2.konvy.com/static/min/css/j/j.v4.85.min.css?v=1626429966 ; media=screen */
@media screen{
div,ul,li{margin:0;padding:0;}
img{border:0;}
ul li{list-style:none;}
a{color:#f73173;text-decoration:none;}
a:hover{text-decoration:underline;}
}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/head_foot.min.css?v=1664360752 */
ul,li{list-style:none;}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/index.min.css?v=1654680463 ; media=screen */
@media screen{
div,ul,li{margin:0;padding:0;}
img{border:0;}
li{list-style:none;}
a{text-decoration:none;}
a:hover{text-decoration:underline;}
}
/*! CSS Used from: https://s2.konvy.com/static/min/css/utils/css/ky.min.css?v=1664420402 */
*{padding:0;margin:0;box-sizing:content-box;}
li,ul{list-style:none;}
img{border:none;}
a:hover,a{text-decoration:none;}
a{color:#333;}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/admin/adminbrand_flagship.min.css?v=1665338270 */
*{margin:0;padding:0;-webkit-text-size-adjust:none;}
li,ul{list-style:none;}
a{text-decoration:none;color:#333;}
img{vertical-align:middle;}
.ContentShow img{border:none;display:inline-block;overflow:hidden;max-width:100%;}
.ContentShow{width:1180px;margin:0 auto;display:none;color:#333;}
.LeftClickLi>.module_banner>li{margin-bottom:1.35%;}
.LeftClickLi>.module_banner>li:last-child{margin-bottom:0;}
.ContentShow .LeftClickLi{padding-bottom:0.1px;}
.moduleWarp{overflow:hidden;}
.LeftClickLi img{width:100%;display:block;overflow:hidden;}
.AllWarp .LeftClickLi img{vertical-align:middle;width:100%;}
.ContentShow .LeftClickLi li{overflow:hidden;}
.module_banner>li{position:relative;}
.ContentShow .li-nohover:hover{box-shadow:none;}
.ContentShow .li-nohover a{cursor:initial;}
.over-in>li{overflow:inherit!important;height:auto!important;}
@media (max-width:1440px){
.ContentShow{width:1000px;}
}
@media (max-width:1360px){
.ContentShow{width:950px;}
}
.ContentShow{position:relative;}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/web/promo_new.min.css?v=1661745912 */
.ContentShow img{max-width:100%;}
@media (max-width:1440px){
.ContentShow{width:950px;}
}
.moduleWarp{display:none;}
.moduleWarp>.ContentShow{display:block;position:relative;}/*! CSS Used from: https://s2.konvy.com/static/min/css/j/j.v4.85.min.css?v=1626429966 ; media=screen */
@media screen{
div,ul,li{margin:0;padding:0;}
img{border:0;}
ul li{list-style:none;}
a{color:#f73173;text-decoration:none;}
a:hover{text-decoration:underline;}
}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/head_foot.min.css?v=1664360752 */
ul,li{list-style:none;}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/index.min.css?v=1654680463 ; media=screen */
@media screen{
div,ul,li{margin:0;padding:0;}
img{border:0;}
li{list-style:none;}
a{text-decoration:none;}
a:hover{text-decoration:underline;}
}
/*! CSS Used from: https://s2.konvy.com/static/min/css/utils/css/ky.min.css?v=1664420402 */
*{padding:0;margin:0;box-sizing:content-box;}
li,ul{list-style:none;}
img{border:none;}
a:hover,a{text-decoration:none;}
a{color:#333;}
.public-swiper-pagination{text-align:center;}
.public-swiper-pagination .swiper-pagination{background:rgba(0,0,0,.16);display:inline-block;left:50%;transform:translate( -50%,0%);width:auto;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;font-size:0;}
/*! CSS Used from: https://s2.konvy.com/static/css/admin/swiper.min.css */
.swiper-wrapper{position:relative;width:100%;height:100%;z-index:1;display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;-webkit-transition-property:-webkit-transform;-moz-transition-property:-moz-transform;-o-transition-property:-o-transform;-ms-transition-property:-ms-transform;transition-property:transform;-webkit-box-sizing:content-box;-moz-box-sizing:content-box;box-sizing:content-box;}
.swiper-wrapper{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-o-transform:translate(0,0);-ms-transform:translate3d(0,0,0);transform:translate3d(0,0,0);}
.swiper-slide{-webkit-flex-shrink:0;-ms-flex:0 0 auto;flex-shrink:0;width:100%;height:100%;position:relative;}
.swiper-pagination{position:absolute;text-align:center;-webkit-transition:.3s;-moz-transition:.3s;-o-transition:.3s;transition:.3s;-webkit-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0);z-index:10;}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/admin/adminbrand_flagship.min.css?v=1665338270 */
*{margin:0;padding:0;-webkit-text-size-adjust:none;}
li,ul{list-style:none;}
a{text-decoration:none;color:#333;}
img{vertical-align:middle;}
.ContentShow img{border:none;display:inline-block;overflow:hidden;max-width:100%;}
.ContentShow{width:1180px;margin:0 auto;display:none;color:#333;}
.LeftClickLi>.module_banner>li{margin-bottom:1.35%;}
.LeftClickLi>.module_banner>li:last-child{margin-bottom:0;}
.ContentShow .LeftClickLi{padding-bottom:0.1px;}
.moduleWarp{overflow:hidden;}
.swiper-slide a{width:100%;height:100%;display:block;}
.LeftClickLi img{width:100%;display:block;overflow:hidden;}
.AllWarp .LeftClickLi img{vertical-align:middle;width:100%;}
.ContentShow .LeftClickLi li{overflow:hidden;}
.module_banner>li{position:relative;}
.swiper-team-warp{position:relative;overflow:hidden;}
.swiper-team-warp{position:relative;overflow:hidden;}
.swiper-team-warp .swiper-slide{height:auto;}
.swiper-team-warp .swiper-slide a{height:auto;width:auto;text-decoration:none;}
.swiper-team-warp .swiper-wrapper{height:auto;}
.over-in>li{overflow:inherit!important;height:auto!important;}
@media (max-width:1440px){
.ContentShow{width:1000px;}
}
@media (max-width:1360px){
.ContentShow{width:950px;}
}
.ContentShow{position:relative;}
/*! CSS Used from: Embedded */
@media (max-width: 1440px){
.ContentShow{width:1000px;padding-left:0;}
}
@media (max-width: 1360px){
.ContentShow{width:950px;padding-left:0;}
}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/web/promo_new.min.css?v=1661745912 */
.ContentShow img{max-width:100%;}
@media (max-width:1440px){
.ContentShow{width:950px;}
}
.moduleWarp{display:none;}
.moduleWarp>.ContentShow{display:block;position:relative;}
.swiper_prev_nst,.swiper_next_nst{position:absolute;top:50%;margin-top:-32px;width:40px;background-size:40px;height:40px;display:none;}
.swiper_prev_nst{left:-60px;background-image:url(https://s2.konvy.com/static/banner/module_image/pc/67/btn_left@2x.png);}
.swiper_next_nst{right:-60px;background-image:url(https://s2.konvy.com/static/banner/module_image/pc/67/btn_right@2x.png);}
.swiper_prev_nst:hover{background-image:url(https://s2.konvy.com/static/banner/module_image/pc/67/btn_left_hover@2x.png);}
.swiper_next_nst:hover{background-image:url(https://s2.konvy.com/static/banner/module_image/pc/67/btn_right_hover@2x.png);}
.public-swiper-pagination{text-align:center;height:32px;padding-top:15px;display:none;}
.public-swiper-pagination .swiper-pagination{background:rgba(0,0,0,.16);border-radius:32px;padding:3px 30px;display:inline-block;left:50%;transform:translate( -50%,0%);width:auto;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
</style>
